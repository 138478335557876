
import {
  defineComponent, PropType, ref,
} from '@vue/composition-api';
import FindReplace from '@/views/SubmissionPortal/Components/FindReplace.vue';
import type { HarmonizerApi } from '@/views/SubmissionPortal/harmonizerApi';
import ContactCard from '@/views/SubmissionPortal/Components/ContactCard.vue';
import ImportExportButtons from '@/views/SubmissionPortal/Components/ImportExportButtons.vue';
import ColumnHelp from '@/views/SubmissionPortal/Components/ColumnHelp.vue';
import MetadataSuggester from '@/views/SubmissionPortal/Components/MetadataSuggester.vue';
import { ColumnHelpInfo, HarmonizerTemplateInfo } from '@/views/SubmissionPortal/types';

/**
 * The tabbed Data Harmonizer sidebar.
 */
export default defineComponent({
  components: {
    MetadataSuggester,
    ColumnHelp,
    ImportExportButtons,
    ContactCard,
    FindReplace,
  },
  props: {
    /**
     * Help information for the currently selected column.
     */
    columnHelp: {
      type: Object as PropType<ColumnHelpInfo | null>,
      default: null,
    },
    /**
     * Information about the active template.
     */
    harmonizerTemplate: {
      type: Object as PropType<HarmonizerTemplateInfo>,
      required: true,
    },
    /**
     * Whether the current user is allowed to edit metadata.
     */
    metadataEditingAllowed: {
      type: Boolean,
      default: false,
    },
    /**
     * The Harmonizer API instance.
     */
    harmonizerApi: {
      type: Object as PropType<HarmonizerApi>,
      required: true,
    },
  },
  emits: ['export-xlsx', 'import-xlsx'],
  setup(props, { emit }) {
    const tabModel = ref(0);
    const TABS = [
      {
        icon: 'mdi-information-outline',
        label: 'Column Info',
      },
      {
        icon: 'mdi-text-search',
        label: 'Find & Replace',
      },
      {
        icon: 'mdi-assistant',
        label: 'Metadata Suggester',
      },
      {
        icon: 'mdi-swap-vertical',
        label: 'Import & Export',
      },
      {
        icon: 'mdi-help-circle-outline',
        label: 'Help',
      },
    ];

    const handleImport = (...args: never[]) => {
      emit('import-xlsx', ...args);
    };

    return {
      TABS,
      handleImport,
      tabModel,
    };
  },
});
