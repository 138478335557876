
import {
  defineComponent,
  ref,
  onMounted,
  watch,
  nextTick,
} from '@vue/composition-api';
// @ts-ignore
import NmdcSchema from 'nmdc-schema/nmdc_schema/nmdc_materialized_patterns.yaml';
import Definitions from '@/definitions';
import {
  contextForm,
  contextFormValid,
  addressFormValid,
  canEditSubmissionMetadata,
} from '../store';
import { AwardTypes } from '@/views/SubmissionPortal/types';
import SubmissionContextShippingForm from './SubmissionContextShippingForm.vue';
import SubmissionDocsLink from './SubmissionDocsLink.vue';
import SubmissionPermissionBanner from './SubmissionPermissionBanner.vue';

export default defineComponent({
  components: { SubmissionContextShippingForm, SubmissionDocsLink, SubmissionPermissionBanner },
  setup() {
    const formRef = ref();
    const facilityEnum = Object.keys(NmdcSchema.enums.ProcessingInstitutionEnum.permissible_values).filter(
      (facility: string) => ['EMSL', 'JGI'].includes(facility),
    );
    const projectAwardValidationRules = () => [(v: string) => {
      const awardTypes = Object.values(AwardTypes) as string[];
      const awardChosen = awardTypes.includes(v) || v === contextForm.otherAward;
      const valid = awardChosen || contextForm.facilities.length === 0;
      return valid || 'If submitting to a use facility, this field is required.';
    }];
    const otherAwardValidationRules = () => [(v: string) => {
      const awardTypes = Object.values(AwardTypes) as string[];
      if (contextForm.award && awardTypes.includes(contextForm.award)) {
        return true;
      }
      const inputEmpty = v.trim().length === 0;
      if (contextForm.facilities.length > 0) {
        return !inputEmpty || 'Please enter the kind of project award.';
      }
      return true;
    }];
    const doiRequiredRules = () => [(v: string) => {
      const valid = !!v || (!contextForm.facilityGenerated && contextForm.dataGenerated) || (contextForm.unknownDoi && !contextForm.dataGenerated);
      return valid || 'An award DOI is required if data was or is going to be generated by a DOE facility.';
    }];
    const revalidate = () => {
      nextTick(() => formRef.value.validate());
    };

    function addAwardDoi() {
      if (contextForm.awardDois === null || contextForm.awardDois.length === 0) {
        contextForm.awardDois = [''];
      } else {
        contextForm.awardDois.push('');
      }
    }

    function removeAwardDoi(i: number) {
      if (contextForm.awardDois === null) {
        contextForm.awardDois = [''];
      }
      if ((contextForm.facilities.length < contextForm.awardDois.length && !contextForm.dataGenerated) || (contextForm.facilityGenerated && contextForm.dataGenerated && contextForm.awardDois.length > 1) || (!contextForm.facilityGenerated && contextForm.dataGenerated)) {
        contextForm.awardDois.splice(i, 1);
      }
    }

    function facilityChange() {
      if (contextForm.awardDois === null || contextForm.awardDois.length < contextForm.facilities.length) {
        addAwardDoi();
      }
      revalidate();
    }

    function facilityGeneratedChange() {
      if (contextForm.facilityGenerated && (contextForm.awardDois === null || contextForm.awardDois.length < 1)) {
        addAwardDoi();
      }
      revalidate();
    }

    watch(
      () => contextForm.award,
      (award) => {
        const awardTypes = Object.values(AwardTypes) as string[];
        if (award && awardTypes.includes(award)) {
          contextForm.otherAward = '';
        }
        revalidate();
      },
    );

    onMounted(() => {
      formRef.value.validate();
    });

    return {
      Definitions,
      facilityEnum,
      formRef,
      contextForm,
      contextFormValid,
      addressFormValid,
      projectAwardValidationRules,
      otherAwardValidationRules,
      doiRequiredRules,
      revalidate,
      addAwardDoi,
      removeAwardDoi,
      facilityChange,
      facilityGeneratedChange,
      canEditSubmissionMetadata,
    };
  },
});
