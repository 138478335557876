
import { defineComponent, PropType } from '@vue/composition-api';
import { urlify } from '@/data/utils';
import { ColumnHelpInfo, HarmonizerTemplateInfo } from '@/views/SubmissionPortal/types';

export default defineComponent({
  props: {
    columnHelp: {
      type: Object as PropType<ColumnHelpInfo | null>,
      default: null,
    },
    harmonizerTemplate: {
      type: Object as PropType<HarmonizerTemplateInfo>,
      required: true,
    },
  },
  emits: ['full-reference-click'],
  setup() {
    return {
      urlify,
    };
  },
});
