
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  props: {
    importDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['export', 'import'],
  setup(props, { emit }) {
    const xlsxFileInput = ref();

    function showOpenFileDialog() {
      xlsxFileInput.value.click();
    }

    function handleFileInputChange(event: Event) {
      const target = event.target as HTMLInputElement;
      if (!target || !target.files) {
        return;
      }
      emit('import', target.files[0]);

      // Reset the file input so that the same filename can be loaded multiple times
      target.value = '';
    }

    return {
      handleFileInputChange,
      showOpenFileDialog,
      xlsxFileInput,
    };
  },
});
