
import { defineComponent, ref } from '@vue/composition-api';
import moment from 'moment';
import { jwtDecode } from 'jwt-decode';
import AppBanner from '@/components/AppBanner.vue';
import OrcidId from '@/components/Presentation/OrcidId.vue';
import { stateRefs } from '@/store';
import { getRefreshToken } from '@/store/localStorage';
import { User } from '@/types';
import { api } from '@/data/api';

export default defineComponent({
  name: 'UserDetailPage',
  components: { OrcidId, AppBanner },

  setup() {
    const refreshTokenInput = ref();

    const refreshToken = getRefreshToken();
    let refreshTokenExpirationDate;
    if (refreshToken != null) {
      const decodedToken = jwtDecode(refreshToken);
      if (decodedToken.exp != null) {
        refreshTokenExpirationDate = moment.unix(decodedToken.exp).format('YYYY-MM-DD HH:mm:ss');
      }
    }
    const isTokenVisible = ref(false);
    const isCopyRefreshTokenSnackbarVisible = ref(false);

    const handleRefreshTokenCopyButtonClick = async () => {
      if (refreshToken != null) {
        await navigator.clipboard.writeText(refreshToken);
        isCopyRefreshTokenSnackbarVisible.value = true;
      }
    };
    const { user, userLoading } = stateRefs;
    const handleRefreshTokenVisibilityButtonClick = async () => {
      isTokenVisible.value = !isTokenVisible.value;
      if (isTokenVisible.value) {
        // Wait for the type of the input to change before selecting the text.
        // For some reason, nextTick isn't enough in this case.
        setTimeout(() => {
          refreshTokenInput.value.$refs.input.select();
        }, 50);
      }
    };
    function requiredRules(msg: string, otherRules: ((v: string) => unknown)[] = []) {
      return [
        (v: string) => !!v || msg,
        ...otherRules,
      ];
    }
    const updateUser = async (value:string) => {
      const update: User = {
        id: user.value?.id as string,
        orcid: user.value?.orcid as string,
        name: user.value?.name as string,
        email: value,
        is_admin: user.value?.is_admin as boolean,
      };
      await api.updateUser(user.value?.id as string, update);
    };
    const editEmail = ref(false);

    const isEmailValid = ref(false);

    const updateEmail = (email: string | undefined) => {
      if (editEmail.value) {
        if (email == null) {
          return;
        }
        isEmailValid.value = /.+@.+\..+/.test(email);
        if (isEmailValid.value) {
          updateUser(email);
          editEmail.value = !editEmail.value;
        }
      } else {
        editEmail.value = !editEmail.value;
      }
    };

    return {
      editEmail,
      updateEmail,
      isEmailValid,
      user,
      userLoading,
      origin: window.location.origin,
      refreshToken,
      refreshTokenExpirationDate,
      refreshTokenInput,
      isCopyRefreshTokenSnackbarVisible,
      isTokenVisible,
      handleRefreshTokenVisibilityButtonClick,
      handleRefreshTokenCopyButtonClick,
      updateUser,
      requiredRules,
    };
  },
});
