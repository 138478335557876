
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    elevation: {
      type: String,
      default: '24',
    },
  },
  setup() {
    function emailTemplate(value: string) {
      const subjects: { [key: string]: string } = {
        support: 'Support Request',
        bug: 'Bug Report',
        feature: 'Feature Request',
      };
      const subject = encodeURIComponent(subjects[value]);
      return `mailto:support@microbiomedata.org?subject=${subject}`;
    }
    return {
      emailTemplate,
    };
  },
});
