var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(" Metadata Suggester "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","min-width":"300px","max-width":"600px","open-delay":_vm.TOOLTIP_DELAY,"z-index":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" As you enter sample metadata, the Metadata Suggester will offer suggestions for metadata values based on the metadata values you have already entered. ")])])],1),(_vm.enabled)?_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.suggestionModeOptions,"dense":"","hide-details":"","label":"Suggestion Mode","outlined":""},model:{value:(_vm.suggestionMode),callback:function ($$v) {_vm.suggestionMode=$$v},expression:"suggestionMode"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.suggestionTypeOptions,"dense":"","hide-details":"","label":"Suggestion Type","outlined":""},model:{value:(_vm.suggestionType),callback:function ($$v) {_vm.suggestionType=$$v},expression:"suggestionType"}})],1)],1),(_vm.suggestionMode === _vm.SuggestionsMode.ON_DEMAND)?_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","block":"","loading":_vm.onDemandSuggestionsLoading},on:{"click":_vm.handleSuggestForSelectedRows}},[_vm._v(" Suggest for Selected Rows ")])],1)],1):_vm._e(),(_vm.hasSuggestions)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"text-body-1 text--primary font-weight-medium"},[_vm._v(" All Suggestions ")]),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","open-delay":_vm.TOOLTIP_DELAY},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":_vm.handleRejectAllSuggestions}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}],null,false,1788744042)},[_c('span',[_vm._v("Reject all suggestions")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":_vm.TOOLTIP_DELAY},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":_vm.handleAcceptAllSuggestions}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-check ")])],1)]}}],null,false,695921013)},[_c('span',[_vm._v("Accept all suggestions")])])],1)])])],1):_vm._e(),_c('v-row',[_c('v-col',[(!_vm.hasSuggestions)?_c('div',{staticClass:"text--disabled"},[_vm._v(" No suggestions available. ")]):_vm._e(),_vm._l((_vm.suggestionsByRow),function(suggestion,row){return _c('div',{key:row},[_c('div',{staticClass:"text-body-2"},[_vm._v(" Row: "+_vm._s(Number(row) + 1)+" ")]),_c('v-sheet',{key:row,staticClass:"mb-4",attrs:{"elevation":"0","rounded":"","outlined":""}},_vm._l((suggestion),function(s){return _c('div',{key:s.slot,staticClass:"ma-2"},[_c('div',{staticClass:"flex-grow-1 full-width"},[_c('div',{staticClass:"text-body-2"},[_c('span',{staticClass:"grey--text"},[_vm._v("Column:")]),_vm._v(" "+_vm._s(_vm.getSlotTitle(s.slot))+" ")])]),_c('div',{staticClass:"d-flex flex-wrap align-center justify-end"},[_c('div',{staticClass:"flex-grow-1"},[(s.current_value)?_c('span',{staticClass:"value previous",domProps:{"textContent":_vm._s(s.current_value)}}):_vm._e(),_c('span',{staticClass:"value suggested",domProps:{"textContent":_vm._s(s.value)}})]),_c('div',{staticClass:"flex-shrink-0 flex-grow-0"},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":_vm.TOOLTIP_DELAY},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.handleJumpToCell(s)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-target ")])],1)]}}],null,true)},[_c('span',[_vm._v("Jump to cell")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":_vm.TOOLTIP_DELAY},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.handleRejectSuggestion(s)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("Reject suggestion")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":_vm.TOOLTIP_DELAY},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.handleAcceptSuggestion(s)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-check ")])],1)]}}],null,true)},[_c('span',[_vm._v("Accept suggestion")])])],1)])])}),0)],1)})],2)],1),(_vm.rejectedSuggestions.length > 0)?_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"grey","outlined":"","small":"","block":""},on:{"click":_vm.handleResetRejectedSuggestions}},[_vm._v(" Reset rejected suggestions ")])],1)],1):_vm._e()],1):_c('v-card-text',[_vm._v(" Suggestions are disabled because you do not have permission to edit the metadata. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }