
import { defineComponent, ref, watch } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';
import { api } from '@/data/api';
import { stateRefs } from '@/store';
import { User } from '@/types';
import usePaginatedResults from '@/use/usePaginatedResults';
import AppBanner from '@/components/AppBanner.vue';
import OrcidId from '../../components/Presentation/OrcidId.vue';

export default defineComponent({
  components: { AppBanner, OrcidId },

  setup() {
    const currentUser = stateRefs.user;
    const itemsPerPage = 10;
    const options = ref({
      page: 1,
      itemsPerPage,
    });
    const headers : DataTableHeader[] = [
      {
        text: 'ORCID',
        align: 'start',
        sortable: false,
        value: 'orcid',
      },
      { text: 'Name', value: 'name', sortable: false },
      { text: 'Admin', value: 'is_admin', sortable: false },
    ];

    const users = usePaginatedResults(ref([]), api.getAllUsers, ref([]), itemsPerPage);
    watch(options, () => users.setPage(options.value.page), { deep: true });

    async function updateAdminStatus(item: User) {
      await api.updateUser(item.id, item);
    }

    return {
      headers,
      users,
      updateAdminStatus,
      options,
      currentUser,
    };
  },
});
